<template>
  <b-modal
    :id="`link-evidence-with-qualification-record-${qrId}`"
    title="Link Evidence to Qualification Record"
    lazy
    centered
    size="lg"
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <b-form-group v-if="localRecord" label="Evidence" label-for="ddl-evidence-links">
      <v-select
        id="ddl-evidence-links"
        v-model="evidence"
        :options="allEvidence"
        :reduce="obj => obj.id"
        :disabled="loadingRecord"
        label="title"
      />
    </b-form-group>

    <b-form-group label="Assessed On" label-for="assessed-date">
      <b-form-datepicker
        id="assessed-date"
        v-model="assessedDate"
      />
    </b-form-group>

    <!--<b-form-group label="Assessed Time" label-for="assessed-time">-->
    <!--  <b-form-timepicker-->
    <!--    id="assessed-time"-->
    <!--    v-model="assessedTime"-->
    <!--    now-button-->
    <!--    locale="en"-->
    <!--  />-->
    <!--</b-form-group>-->

    <b-form-group label="Assessed By" label-for="assessed-by">
      <b-form-input id="assessed-by" v-model="assessedBy" />
    </b-form-group>

    <!-- Modal Buttons -->
    <template v-slot:modal-footer>
      <b-button variant="outline-secondary" @click="onClose">
        Discard
      </b-button>
      <b-button
        :disabled="loadingRecord || loadingEvidence || !evidence"
        variant="success"
        @click="onSubmit"
      >
        <span v-if="saving">
          <b-spinner small type="grow" />
          Saving...
        </span>
        <span v-else>
          Save Link
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  name: 'LinkEvidenceWithQualificationRecord',
  components: { vSelect },
  props: {
    qrId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, context) {
    const saving = ref(false)
    const loadingRecord = ref(false)
    const loadingEvidence = ref(false)

    const localRecord = ref({})
    const localRecordEtag = ref('')
    const allEvidence = ref([])

    const evidence = ref('')
    const assessedBy = ref('')
    const assessedDate = ref('')
    // const assessedTime = ref('')
    // const assessedDateTime = computed(() => moment(
    //   String(`${assessedDate.value} ${assessedTime.value}`),
    // ).format('YYYY-MM-DD hh:mm'))

    const onClose = () => {
      loadingRecord.value = false
      loadingEvidence.value = false
      localRecord.value = {}
      evidence.value = ''
      assessedBy.value = ''
      assessedDate.value = ''
      // assessedTime.value = ''
      context.root.$bvModal.hide(`link-evidence-with-qualification-record-${props.qrId}`)
    }
    const onShow = () => {
      loadingRecord.value = true
      store.dispatch('qualificationRecords/getRecord', props.qrId)
        .then(response => {
          localRecord.value = response.data
          localRecordEtag.value = response.headers.etag
        })
        .finally(() => { loadingRecord.value = false })

      loadingEvidence.value = true
      store.dispatch('qualificationEvidence/fetchEvidenceList')
        .then(data => { allEvidence.value = data })
        .finally(() => { loadingEvidence.value = false })
    }
    const onSubmit = () => {
      saving.value = true
      const payload = {
        etag: localRecordEtag.value,
        qrId: props.qrId,
        evidenceId: evidence.value,
        assessed: assessedDate.value,
        assessedBy: assessedBy.value,
      }

      store.dispatch('qualificationRecords/saveEvidenceLinkWithRecord', payload)
        .then(() => {
          context.emit('on-linked')
          onClose()
        })
        .finally(() => { saving.value = false })
    }

    return {
      loadingRecord,
      loadingEvidence,
      saving,

      localRecord,
      assessedDate,
      // assessedTime,
      // assessedDateTime,
      assessedBy,
      evidence,
      allEvidence,

      onShow,
      onClose,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

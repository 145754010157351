<template>
  <b-modal
    :id="`delete-qualification-record-${qrId}`"
    title="Delete Qualification Record"
    centered
    cancel-title="Dismiss"
    cancel-variant="outline-secondary"
    ok-title="Delete"
    ok-variant="danger"
    @show="onShow"
    @ok="onDelete"
  >
    <p>Are you sure you want to delete this qualification record?</p>

    <div class="flex-column">
      <div>
        <feather-icon
          icon="ToolIcon"
          class="text-primary"
          size="16"
        />
        <span class="ml-50 font-small-3">
          <span class="text-primary mr-25">Qualification Method:</span>
          <span :class="localRecord.method ? 'font-weight-bold' : 'text-muted'">
            {{ localRecord.method || 'n/a' }}
          </span>
        </span>
      </div>
      <div>
        <feather-icon
          icon="RepeatIcon"
          class="text-primary"
          size="16"
        />
        <span class="ml-50 font-small-3">
          <span class="text-primary mr-25">Qualification Phase:</span>
          <span :class="localRecord.phase ? 'font-weight-bold' : 'text-muted'">
            {{ localRecord.phase || 'n/a' }}
          </span>
        </span>
      </div>
      <div>
        <feather-icon
          icon="ClipboardIcon"
          class="text-primary"
          size="16"
        />
        <div class="d-inline ml-50 font-small-3">
          <span class="text-primary mr-25">Qualification Statement:</span>
          <p class="ml-3 mb-0" v-sanitized-html="localRecord.statement" />
        </div>
      </div>
      <div>
        <font-awesome-icon
          :icon="['fas', 'vials']"
          class="text-primary mr-25"
        />
        <span class="ml-50 font-small-3">
          <span class="text-primary mr-25">Result:</span>
          <span v-if="localRecord.record_result === 'Fail'" class="font-weight-bolder text-danger">
            {{ localRecord.record_result }}
          </span>
          <span v-else :class="localRecord.record_result ? 'font-weight-bold' : 'text-muted'">
            {{ localRecord.record_result || 'Not determined' }}
          </span>
        </span>
      </div>
    </div>
  </b-modal>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'

export default {
  name: 'DeleteQualificationRecordModal',
  props: {
    qrId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const localRecord = ref({})
    const localRecordETag = ref('')

    const onShow = () => {
      loading.value = true
      store.dispatch('qualificationRecords/getRecord', props.qrId)
        .then(response => {
          localRecord.value = response.data
          localRecordETag.value = response.headers.etag
        })
        .finally(() => { loading.value = false })
    }
    const onDelete = () => {
      const data = {
        id: localRecord.value.id,
        etag: localRecordETag.value,
      }
      store.dispatch('qualificationRecords/deleteQualificationRecord', data)
        .then(response => {
          if (response === true) { context.emit('on-delete') }
        })
        .finally(() => {
          context.root.$bvModal.hide(`delete-qualification-record-${props.qrId}`)
        })
    }

    return {
      localRecord,

      onShow,
      onDelete,
    }
  },
}
</script>

<template>
  <b-modal
    :id="`update-qualification-record-${qrId}`"
    title="Update Qualification Record"
    lazy
    centered
    size="lg"
    no-close-on-backdrop
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <div>
      <b-form-group label="Qualification Method" label-for="ddl-qualification-method">
        <v-select
          id="ddl-qualification-method"
          v-model="localRecord.method"
          :options="methodOptions"
          :clearable="false"
          :disabled="loading || updating"
          :reduce="obj => obj.id"
          label="name"
        />
      </b-form-group>

      <b-form-group label="Qualification Phase" label-for="ddl-qualification-phase">
        <v-select
          id="ddl-qualification-phase"
          v-model="localRecord.phase"
          :options="phaseOptions"
          :clearable="false"
          :disabled="loading || updating"
          :reduce="obj => obj.id"
          label="name"
        />
      </b-form-group>

      <b-form-group label="Qualification Statement" label-for="ddl-qualification-statement">
        <tip-tap-editor
          id="ddl-qualification-statement"
          v-model="localRecord.statement"
          :allow-image-upload="false"
          :disabled="loading || updating"
          placeholder="Enter a Qualification Statement..."
          min-height="10"
          max-height="10"
        />
      </b-form-group>

      <b-form-group label="Result" label-for="record-result">
        <v-select
          v-model="localRecord.record_result"
          :options="resultOptions"
          :clearable="false"
          :disabled="loading || updating"
          :reduce="obj => obj.id"
          label="name"
        />
      </b-form-group>

      <hr class="my-2">

      <b-form-group v-if="localRecord.evidence.length > 0" label="Cited Evidence" label-for="evidence-list">
        <b-list-group id="evidence-list">
          <b-list-group-item
            v-for="(ev, index) in localRecord.evidence"
            :key="index"
            class="entity"
          >
            <div class="d-flex flex-row justify-content-between">
              <div id="records-item-text" class="d-flex flex-column">
                <span class="font-weight-bolder text-primary mt-50">
                  {{ ev.title }}
                  <span class="font-small-2">(version: {{ ev.version }})</span>
                </span>
              </div>

              <!-- Icons -->
              <b-button
                variant="flat-danger"
                size="sm"
                @click="deleteEvidenceLink(ev.id)"
              >
                <feather-icon icon="TrashIcon" size="16" />
              </b-button>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
      <p v-else class="text-muted ml-25">
        No evidence cited for this qualification record.
      </p>
    </div>

    <!-- Modal Buttons -->
    <template v-slot:modal-footer>
      <b-button
        v-b-tooltip.hover.top="'Record History'"
        variant="outline-warning"
        class="btn-icon"
        :disabled="loading || updating"
        @click="openHistory"
      >
        <font-awesome-icon :icon="['fas', 'timeline']" />
      </b-button>
      <b-button
        variant="outline-secondary"
        :disabled="updating"
        @click="onClose"
      >
        Discard
      </b-button>
      <b-button
        :disabled="loading || updating"
        variant="success"
        @click="onSubmit"
      >
        <span v-if="updating">
          <b-spinner small type="grow" />
          Saving...
        </span>
        <span v-else>
          Save changes
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  name: 'UpdateQualificationRecordModal',
  components: {
    vSelect,
    TipTapEditor,
  },
  props: {
    qrId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props, context) {
    const methodOptions = computed(() => store.getters['constants/qualificationMethods'])
    const phaseOptions = computed(() => store.getters['constants/qualificationPhases'])
    const resultOptions = computed(() => store.getters['constants/qualificationResults'])

    const loading = ref(false)
    const updating = ref(false)
    const localRecord = ref({
      method: '',
      phase: '',
      statement: '',
      record_result: '',
      evidence: [],
    })
    const localRecordETag = ref('')

    const onShow = () => {
      loading.value = true
      store.dispatch('qualificationRecords/getRecord', props.qrId)
        .then(response => {
          localRecord.value = response.data
          localRecordETag.value = response.headers.etag
        })
        .finally(() => { loading.value = false })
    }
    const onClose = () => {
      loading.value = false
      updating.value = false
      localRecord.value = {
        method: '',
        phase: '',
        statement: '',
        record_result: '',
        evidence: [],
      }
      localRecordETag.value = null
      context.root.$bvModal.hide(`update-qualification-record-${props.qrId}`)
    }
    const onSubmit = () => {
      const data = {
        id: props.qrId,
        etag: localRecordETag.value,
        method: localRecord.value.method,
        phase: localRecord.value.phase,
        statement: localRecord.value.statement,
        record_result: localRecord.value.record_result,
      }

      updating.value = true
      store.dispatch('qualificationRecords/updateQualificationRecord', data)
        .then(data => { context.emit('on-update', data) })
        .finally(() => {
          updating.value = false
          onClose()
        })
    }
    const deleteEvidenceLink = evidenceId => {
      const payload = { qrId: props.qrId, evidenceId }
      store.dispatch('qualificationRecords/removeEvidenceLinkWithRecord', payload)
        .then(response => { onShow() })
    }
    const openHistory = () => {
      console.log('openHistory')
    }

    return {
      loading,
      updating,
      methodOptions,
      phaseOptions,
      resultOptions,

      localRecord,

      onShow,
      onClose,
      onSubmit,
      deleteEvidenceLink,
      openHistory,
    }
  },
  // methods: {
  //   disassociateEvidence(evidence) {
  //     this.$store
  //       .dispatch('qualificationRecords/disassociateEvidence', evidence)
  //       .then(() => {
  //         this.evidence = this.$store.state.qualificationRecords.selectedRecord.evidence
  //       })
  //   },
  //   async openHistory() {
  //     const opts = {
  //       centered: true,
  //       okVariant: 'danger',
  //     }
  //     const response = await this.$bvModal.msgBoxConfirm('Close this and view history page?', opts)
  //     if (!response) {
  //       return
  //     }
  //     this.$bvModal.hide('update-qualification-record')
  //     this.$router.push(
  //       {
  //         name: 'node_history',
  //         params: {
  //           modelId: sessionStorage.getItem('kompozition-workspace'),
  //           nodeId: this.record.id,
  //         },
  //       },
  //     )
  //   },
  // },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
